
import {
    defineComponent, ref, Ref, watch,
    computed, PropType, inject
} from 'vue';
import customizePagination from '../../customize-pagination';
import list from './list.vue';
import listBaseProps from '../util/list-base-prop';
import chooseTag from '../components/choose-tag.vue';
import { ListContent, RequestData } from '../util/all-interface';
import { getSlotName, isNotUseRequestData } from '../util/util';

export default defineComponent({
    components: {
        list,
        chooseTag,
        customizePagination
    },
    props: {
        ...listBaseProps,
        chooseLabelDeal: {
            type: Function as PropType<(item: ListContent) => string>
        },
        listType: {
            type: String as PropType<'normal' | 'pagination'>,
            default: 'normal'
        },
        row: {
            type: Number,
            default: 1
        },
        showLength: {
            type: Boolean,
            default: false
        },
        showChooseTitle: {
            type: Boolean,
            default: true
        },
        /**
         * 表头显示方式
         * default:默认，展示已选择标签，不现实总条数
         * total: 显示总条数
         */
        chooseTitleType: {
            type: String,
            default: 'default'
        }
    },
    setup(props, { emit }) {
        // isOpenVirtual：是否开启虚拟类别功能
        const isOpenVirtual = inject('isOpenVirtual', false);

        const totalNumber = ref(0);
        const pageNumber = ref(1);
        const updateWithRow = ref(0);

        // 赋值page参数，requestDataWithPage不考虑外部参数更改情况
        const requestDataWithPage: Ref<RequestData | false> = ref(props.listType === 'pagination' ? false : props.requestData);

        const serch = (row: number, page: number) => {
            pageNumber.value = page;
            if (isNotUseRequestData(props.requestData)) {
                requestDataWithPage.value = false;
            } else {
                requestDataWithPage.value = {
                    url: props.requestData.url,
                    param: Object.assign(props.requestData.param, {
                        row,
                        page
                    })
                };
                updateWithRow.value += 1;
            }
        };

        const getTotal = (total: number, currentPageCount: number) => {
            totalNumber.value = total;
            emit('getTotal', total, currentPageCount);
        };

        const actionEmit = (eventName: string, value: object) => {
            emit(eventName, value);
        };

        const selectedItemInTag: Ref<Array<string>> = ref([]);
        watch(computed(() => props.selectedItem), () => {
            selectedItemInTag.value = props.selectedItem;
        }, {
            immediate: true
        });

        const choose: Ref<Array<ListContent>> = ref([]);
        const multipleSelect = (checkKeys: Array<string>, checkRows: Array<ListContent>) => {
            emit('multipleSelect', checkKeys, checkRows);
            choose.value = checkRows;
            selectedItemInTag.value = checkKeys;
        };

        const remove = (item: ListContent) => {
            const tempSelectd: Array<string> = [];
            selectedItemInTag.value.forEach((value) => {
                if (value !== item[props.keyOfCheck]) {
                    tempSelectd.push(value);
                }
            });

            selectedItemInTag.value = tempSelectd;
        };

        return {
            choose,
            multipleSelect,
            getTotal,
            actionEmit,
            selectedItemInTag,
            remove,
            totalNumber,
            getSlotName,
            updateWithRow,
            serch,
            pageNumber,
            requestDataWithPage,
            isOpenVirtual
        };
    }

});
